import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import PetParent from "../../../features/quotes-funnel/ui/pages/pet-parent/PetParent";
import { PageId } from "../../../settings/pages";

const Page = (): ReactElement => <PetParent />;

export default withFunnelWrapper(Page, "pg-pet-parent", PageId.petParent);

export const query = graphql`
  query PetParentPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
