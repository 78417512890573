import { CalendarDate, getLocalTimeZone, parseDate, today } from "@internationalized/date";
import { isValid as isNif } from "better-dni";
import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement, useState } from "react";
import * as Yup from "yup";

import Button from "../../../../../atoms/button/Button";
import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import { sanitizePhone } from "../../../../../core/utils/sanitizers";
import PageStepForm from "../../../../../organisms/page-step-form/PageStepForm";
import { Result } from "../../../../../organisms/result/domain/result";
import {
  useResult,
  useUpdateResult,
} from "../../../../../organisms/result/use-cases/result-use-cases";
import { minPetParentAgeNumberOfYears } from "../../../../../settings/pet-parent";
import { Events, track } from "../../../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import {
  getPhoneNumberLength,
  isIdDocumentRequired,
  shouldAddOtherInGenderOptions,
} from "../../../../../utils/locale-configuration-utils";
import SalutationSelect from "../../atoms/salutation-select/SalutationSelect";
import DateFieldFormControl from "../../molecules/date-field-form-control/DateFieldFormControl";
import * as styles from "./PetParent.module.scss";

const petParentSVG: string = require("../../images/pages/pet-parent/womanInfo.svg").default;

interface PetParentFormValues {
  pet_parent_first_name: string;
  pet_parent_last_name: string;
  pet_parent_id_number: string;
  pet_parent_country_code: string;
  pet_parent_phone: string;
  pet_parent_salutation: string;
  pet_parent_birth_date: CalendarDate;
}

const PetParent = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const updateResult = useUpdateResult();
  const nextUrl = `/results/${result?.uuid}/address/`;
  const hasIdDocumentField = isIdDocumentRequired(result?.country);
  const phoneNumberLength = getPhoneNumberLength(result?.country);
  const initialBirthDateValue: CalendarDate | undefined = result?.pet_parent_birth_date
    ? parseDate(result?.pet_parent_birth_date)
    : undefined;
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [validateOnMount, setValidateOnMount] = useState(true);
  const localDate = today(getLocalTimeZone());
  const minValue = new CalendarDate(localDate.year, localDate.month, localDate.day).subtract({
    years: 100,
  });
  const hasOtherInGenderOptions = shouldAddOtherInGenderOptions(result.country);

  useTrackPageViewed(Events.PET_PARENT_VIEWED_BROWSER);

  const handleSubmitError = (err: Error, setSubmitting: (isSubmitting: boolean) => void): void => {
    handleUpdateResultErrors(err, "pet_parent");
    setHasSubmitError(true);
    setSubmitting(false);
  };

  const petParentFormData = {
    initialValues: {
      pet_parent_first_name: result?.pet_parent_first_name || "",
      pet_parent_last_name: result?.pet_parent_last_name || "",
      pet_parent_country_code: result?.pet_parent_country_code || "",
      pet_parent_phone: result?.pet_parent_phone || "",
      pet_parent_salutation: result?.pet_parent_salutation || "",
      pet_parent_birth_date: initialBirthDateValue || undefined,
      ...(hasIdDocumentField && {
        pet_parent_id_number: result?.pet_parent_id_number || "",
      }),
    },
    validateOnMount,
    validationSchema: Yup.object({
      pet_parent_first_name: Yup.string().trim().required(t("common.validation.required")),
      pet_parent_last_name: Yup.string().trim().required(t("common.validation.required")),
      pet_parent_country_code: Yup.string().trim().required(t("common.validation.required")),
      pet_parent_phone: Yup.string()
        .min(phoneNumberLength.min, t("common.validation.required"))
        .trim()
        .required(t("common.validation.required")),
      pet_parent_salutation: Yup.string().required(t("common.validation.required")),
      pet_parent_birth_date: Yup.mixed()
        .required(t("common.validation.required"))
        .test(
          "validate-date",
          t("common.validation.valid_birth_date"),
          (value: CalendarDate): boolean => {
            if (!value) {
              return false;
            }

            const dateBeforeMinValue = value.compare(minValue) >= 0;
            const dateAfterMaxValue = value.compare(localDate) < 0;

            return !(!dateBeforeMinValue || !dateAfterMaxValue);
          }
        )
        .test(
          "is-over-minimum-age",
          t("common.validation.minimum_age"),
          (value: CalendarDate): boolean => {
            if (!value) {
              return true;
            }

            const maxAllowedDate = new CalendarDate(
              localDate.year,
              localDate.month,
              localDate.day
            ).subtract({
              years: minPetParentAgeNumberOfYears,
            });

            return value.compare(maxAllowedDate) <= 0;
          }
        ),
      ...(hasIdDocumentField && {
        pet_parent_id_number: Yup.string()
          .trim()
          .required(t("common.validation.required"))
          .test("is-nif", t("common.validation.id_number"), isNif),
      }),
    }),
    handleSubmit: (
      values: PetParentFormValues,
      { setSubmitting }: FormikHelpers<PetParentFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      setSubmitting(true);
      updateResult({
        ...values,
        pet_parent_birth_date: values.pet_parent_birth_date.toString(),
        pet_parent_phone: sanitizePhone(values.pet_parent_phone, result.country),
      })
        .then((updatedValues) => {
          track(Events.FORM_ANSWERED, {
            eventSender: "Pet parent page",
            answers: {
              ...values,
              pet_parent_phone: updatedValues.pet_parent_phone,
            },
          });

          void navigate(nextUrl);
        })
        .catch((err: Error) => handleSubmitError(err, setSubmitting));
    },
    children: (props: FormikProps<PetParentFormValues>) => {
      const {
        isSubmitting,
        isValid,
        submitCount,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      } = props;
      const handleClick = () => {
        setValidateOnMount(submitCount !== 0);
      };

      const handleBirthdate = (e: CalendarDate): void => {
        if (e && e.year < 1000) {
          return;
        }

        setFieldTouched("pet_parent_birth_date", true);
        setFieldValue("pet_parent_birth_date", e);
      };

      return (
        <Form className={styles.petParentForm}>
          <TextFormControl
            name="pet_parent_first_name"
            label={t("pet_parent.personal_data.first_name.field.title")}
            autoComplete="given-name"
          />
          <TextFormControl
            name="pet_parent_last_name"
            label={t("pet_parent.personal_data.last_name.field.title")}
            autoComplete="family-name"
          />
          <SalutationSelect
            name="pet_parent_salutation"
            label={t("pet_parent.personal_data.salutation.field.title")}
            hasOtherInGenderOptions={hasOtherInGenderOptions}
            adoptionClassName={styles.salutationSelect}
          />
          <div className={styles.birthDateWrapper}>
            <DateFieldFormControl
              name="pet_parent_birth_date"
              defaultValue={initialBirthDateValue}
              onChange={(e: CalendarDate) => handleBirthdate(e)}
              label={t("pet_parent.personal_data.birth_date.field.title")}
            />
            {touched.pet_parent_birth_date && errors.pet_parent_birth_date && (
              <p className={styles.birthDateErrorMessage} role="alert" tabIndex={-1}>
                {String(errors.pet_parent_birth_date)}
              </p>
            )}
          </div>
          {hasIdDocumentField && (
            <TextFormControl
              name="pet_parent_id_number"
              label={t("pet_parent.personal_data.id_number.field.title")}
              onChange={(e) =>
                setFieldValue(
                  "pet_parent_id_number",
                  e.target.value.replace(/\W/g, "").toUpperCase()
                )
              }
            />
          )}
          <div className={styles.phoneWrapper}>
            <TextFormControl
              adoptionClassName={styles.countryCode}
              name="pet_parent_country_code"
              label={t("pet_parent.access.country_code.field.title")}
              type="tel"
              maxLength={4}
              onChange={(e) =>
                setFieldValue("pet_parent_country_code", e.target.value.replace(/\D/g, ""))
              }
            />
            <TextFormControl
              name="pet_parent_phone"
              label={t("pet_parent.access.phone.field.title")}
              type="tel"
              minLength={phoneNumberLength.min}
              maxLength={phoneNumberLength.max}
              onChange={(e) => setFieldValue("pet_parent_phone", e.target.value.replace(/\D/g, ""))}
            />
          </div>
          {hasSubmitError && <p className={cn(styles.errorMessage)}>{t("common.error_message")}</p>}
          <Button
            type="submit"
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            onClick={handleClick}
          >
            {t("pet_parent.cta")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <PageStepForm
            title={t("pg_pet_parent.title")}
            subtitle={parse(
              t("pg_pet_parent.subtitle", {
                petName: result.pet_name,
              })
            )}
            image={petParentSVG}
            formData={petParentFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default PetParent;
