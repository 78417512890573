import { useField } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Select from "../../../../../atoms/select/Select";

interface SalutationSelectProps {
  label: string;
  name: string;
  hasOtherInGenderOptions: boolean;
  adoptionClassName?: string;
}

interface GenderOption {
  value: string;
  label: string;
}

const SalutationSelect = ({
  label,
  name,
  hasOtherInGenderOptions,
  adoptionClassName,
}: SalutationSelectProps): JSX.Element => {
  const [_field, meta, helpers] = useField<string>(name);

  const { setValue } = helpers;
  const { t } = useTranslation();

  const { value } = meta;

  const handleSelect = (gender: GenderOption["value"]) => {
    setValue(gender, true);
  };

  const genderOptions: GenderOption[] = [
    { value: "male", label: t("pet_parent.personal_data.pet_parent_salutation.field.male") },
    { value: "female", label: t("pet_parent.personal_data.pet_parent_salutation.field.female") },
    ...(hasOtherInGenderOptions
      ? [
          {
            value: "other",
            label: t("pet_parent.personal_data.pet_parent_salutation.field.other"),
          },
        ]
      : []),
  ];

  const getLabelSelected = (): React.ReactNode => {
    if (value) {
      const { label } = genderOptions.find((option) => option.value === value) || {};

      return (
        <i className={"value-wrapper"}>
          <span className="listbox-label">{label}</span>
        </i>
      );
    }

    return label;
  };

  return (
    <Select
      name={name}
      label={label}
      buttonLabel={getLabelSelected()}
      options={genderOptions}
      handleChange={handleSelect}
      variantName="salutation-select"
      adoptionClassName={adoptionClassName}
    />
  );
};

export default SalutationSelect;
